<template>
  <div>
    <v-toolbar class="my-5">
      <v-toolbar-title>{{ $t('APARTMENTS.ITEMS') }}</v-toolbar-title>
    </v-toolbar>

    <v-data-table
      v-model="selectedApartments"
      :headers="headers"
      :loading="!!!apartments"
      :items="apartments"
      :sort-by="'id'"
      :sort-desc="true"
      :items-per-page="10"
      show-select
      class="elevation-1"
    >
      <template v-slot:body.prepend>
        <tr>
          <td colspan="1"></td>
          <td>
            <v-text-field
              v-model="filter.name"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.rooms"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.floor"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.inhabitants"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-select
              v-model="filter.status"
              :items="statuses"
              :label="$t('DATA_TABLE.FILTER')"
              small-chips
              dense
              clearable
            ></v-select>
          </td>
        </tr>
      </template>

      <template v-slot:item.status="{ item }">
        {{ $t('APARTMENTS.' + item.status) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Apartment',

  props: {
    apartments: {
      type: Array,
      required: true,
    },
    selectedBuildings: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    selectedApartments: [],
    filter: {},
    apartment: null,
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t('APARTMENTS.APARTMENT_NUMBER_NAME'),
          value: 'name',
          filter: (value) => this.filter_column(value, 'name'),
        },
        {
          text: this.$t('APARTMENTS.ROOMS'),
          value: 'rooms',
          filter: (value) => this.filter_column(value, 'city'),
        },
        {
          text: this.$t('APARTMENTS.FLOOR'),
          value: 'floor',
          filter: (value) => this.filter_column(value, 'floor'),
        },
        {
          text: this.$t('BUILDINGS.INHABITANTS'),
          value: 'inhabitants_count',
          filter: (value) => this.filter_column(value, 'inhabitants'),
        },
        {
          text: this.$t('PROFILE.STATUS'),
          value: 'status',
          filter: (value) => this.filter_column_status(value, 'status'),
        },
      ];
    },
    statuses() {
      return [this.$t('APARTMENTS.FREE'), this.$t('APARTMENTS.OCCUPIED')];
    },
  },

  watch: {
    selectedApartments: function (val) {
      this.$emit('addSelectedApartments', val);
    },
    apartments: function (val) {
      if (val.length === 0) {
        this.selectedApartments = [];
      }
    },
  },

  methods: {
    refreshBuildings: function () {
      this.$emit('refreshBuildings');
    },
    filter_column(value, param) {
      value = value + ''; //  !!! null, undefined
      if (!this.filter[param]) return true;
      if (!value) return false;

      let lcValue = value.toLowerCase(); // (value + '') means value.toString()
      let lcName = this.filter[param].toLowerCase();

      return lcValue.indexOf(lcName) > -1;
    },
    filter_column_status(value, param) {
      value = this.$t('APARTMENTS.' + value);
      if (!this.filter[param]) return true;
      if (!value) return false;

      let lcValue = value.toLowerCase(); // (value + '') means value.toString()
      let lcName = this.filter[param].toLowerCase();

      return lcValue.indexOf(lcName) > -1;
    },
  },
};
</script>
