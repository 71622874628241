<template>
  <v-form
    ref="form"
    v-model="valid"
    class="pt-7"
    @input="$emit('valid', $event)"
  >
    <v-row>
      <v-col cols="12">
        <v-textarea
          class="pa-1"
          name="concierge_info"
          :value="value.concierge_info"
          :counter="1500"
          rows="5"
          outlined
          :rules="rules.concierge_info"
          :readonly="readonly"
          :error-messages="errors.concierge_info"
          :label="`${$t('BUILDING_COMPLEXES.CONCIERGE_INFO')}`"
          @input="updateValue('concierge_info', $event)"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <slot name="buttons" :valid="valid" />
    </v-row>
  </v-form>
</template>

<script>
import { maxLength } from '@/rules';

export default {
  name: 'ConciergeInfoForm',

  inject: ['register', 'unregister'],

  props: {
    value: {
      type: Object,
      default: () => ({
        concierge_info: '',
      }),
    },
    languageId: {
      type: Number,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
      rules: {
        concierge_info: [maxLength(1500)],
      },
    };
  },

  mounted() {
    this.register(this);
    this.$emit('input', { ...this.value, language_id: this.languageId });
  },

  beforeDestroy() {
    this.unregister(this);
  },

  methods: {
    updateValue(key, value) {
      this.$emit('update-prop', key, value);
      this.$emit('input', {
        ...this.value,
        language_id: this.languageId,
        [key]: value,
      });
    },
  },
};
</script>
