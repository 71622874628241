function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.buildingComplex)?_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('BUILDING_COMPLEXES.ITEM'))+" "+_vm._s(_vm.buildingComplex.name)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"indigo"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON.BACK'))+" ")])],1):_vm._e(),(_vm.buildingComplex.name)?_c('v-sheet',{staticClass:"mt-5 pa-5",attrs:{"tile":""}},[(_vm.error)?_c('div',_vm._l((_vm.error.data.errors),function(e,index){return _c('v-alert',{key:index,attrs:{"color":"error","dark":"","dense":""}},[_vm._v(" "+_vm._s(e[0])+" ")])}),1):_vm._e(),_c('v-container',{attrs:{"grid-list-xl":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md8":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('ITEM.NAME'),"readonly":""},model:{value:(_vm.buildingComplex.name),callback:function ($$v) {_vm.$set(_vm.buildingComplex, "name", $$v)},expression:"buildingComplex.name"}})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('ITEM.TYPE'),"readonly":""},model:{value:(_vm.buildingComplex.type),callback:function ($$v) {_vm.$set(_vm.buildingComplex, "type", $$v)},expression:"buildingComplex.type"}})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('PROFILE.STATUS'),"readonly":""},model:{value:(_vm.buildingComplex.status),callback:function ($$v) {_vm.$set(_vm.buildingComplex, "status", $$v)},expression:"buildingComplex.status"}})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('BUILDING_COMPLEXES.MANAGEMENT_COMPANY'),"readonly":""},model:{value:(_vm.buildingComplex.management_company.name),callback:function ($$v) {_vm.$set(_vm.buildingComplex.management_company, "name", $$v)},expression:"buildingComplex.management_company.name"}},on))]}}],null,false,1280493356)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.infoAboutManagementCompany)}})])],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('BUILDING_COMPLEXES.BUSINESS_CUSTOMER'),"readonly":""},model:{value:(_vm.buildingComplex.business_customer.name),callback:function ($$v) {_vm.$set(_vm.buildingComplex.business_customer, "name", $$v)},expression:"buildingComplex.business_customer.name"}},on))]}}],null,false,2735266284)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.infoAboutBisinessCustomer)}})])],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('BUILDING_COMPLEXES.APP'),"readonly":""},model:{value:(_vm.appName),callback:function ($$v) {_vm.appName=$$v},expression:"appName"}})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('PROFILE.ZIP'),"readonly":""},model:{value:(_vm.buildingComplex.zip),callback:function ($$v) {_vm.$set(_vm.buildingComplex, "zip", $$v)},expression:"buildingComplex.zip"}})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"label":_vm.$t('PROFILE.CITY'),"readonly":""},model:{value:(_vm.buildingComplex.city),callback:function ($$v) {_vm.$set(_vm.buildingComplex, "city", $$v)},expression:"buildingComplex.city"}})],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"value":_vm.fullNameOfConcierge,"label":_vm.$t('BUILDING_COMPLEXES.CONCIERGE'),"readonly":""}})],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","md4":""}},[_c('language-tabs',{ref:"languageTabs",attrs:{"errors":_vm.errors.get('translations'),"eager":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var translation = ref.translation;
var index = ref.index;
var language = ref.language;
var on = ref.on;
var rest = objectWithoutProperties( ref, ["translation", "index", "language", "on"] );
var other = rest;
return [_c('concierge-info-form',_vm._g(_vm._b({attrs:{"value":translation,"language-id":language.id,"readonly":_vm.readonly},on:{"update-prop":function($event){return _vm.clearErrorByName($event, ("translations." + index))}}},'concierge-info-form',other,false),on))]}}],null,false,3763412040),model:{value:(_vm.buildingComplex.translations),callback:function ($$v) {_vm.$set(_vm.buildingComplex, "translations", $$v)},expression:"buildingComplex.translations"}})],1),_c('v-flex',{attrs:{"xs12":"","md12":""}},[(_vm.showInfoscreenButton)?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.openInfoscreen}},[_vm._v(" "+_vm._s(_vm.$t('INFOSCREEN.ITEM'))+" ")]):_vm._e()],1)],1)],1)],1):_vm._e(),_c('buildings-of-building-complex',{attrs:{"refresh-buildings":_vm.refreshBuildings,"buildings":_vm.buildingComplex.buildings,"id-building-complex":_vm.id,"selected-building":_vm.selectedBuilding},on:{"addSelectedBuildings":_vm.addSelectedBuildings}}),_c('apartment',{attrs:{"apartments":_vm.shownApartments,"selected-buildings":_vm.selectedBuildings},on:{"refreshBuildings":_vm.refreshBuildingss,"addSelectedApartments":_vm.addSelectedApartments}}),_c('inhabitant',{attrs:{"inhabitants":_vm.shownInhabitants},on:{"refreshBuildings":_vm.refreshBuildingss}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }