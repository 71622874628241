<template>
  <div>
    <v-toolbar class="my-5">
      <v-toolbar-title>{{ $t('BUILDINGS.INHABITANTS') }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
      v-model="selectedInhabitants"
      :headers="headers"
      :loading="!!!inhabitants"
      :items="inhabitants"
      :sort-by="'id'"
      :sort-desc="true"
      :items-per-page="10"
      show-select
      class="elevation-1"
    >
      <template v-slot:body.prepend>
        <tr>
          <td colspan="1"></td>
          <td>
            <v-text-field
              v-model="filter.first_name"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.last_name"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.date_from"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>

          <td>
            <v-text-field
              v-model="filter.date_to"
              :label="$t('DATA_TABLE.FILTER')"
            ></v-text-field>
          </td>

          <td>
            <v-text-field :label="$t('DATA_TABLE.FILTER')"></v-text-field>
          </td>

          <td></td>
        </tr>
      </template>

      <template v-slot:item.date_from="{ item }">
        {{ item.date_from | date_format }}
      </template>

      <template v-slot:item.date_to="{ item }">
        {{ item.date_to | date_format }}
      </template>

      <template v-slot:item.status="{ item }">
        {{ calcStatus(item.date_from, item.date_to) }}
      </template>

      <template v-slot:item.action="{ item }">
        <div class="text-center">
          <v-list-item
            target="_blank"
            :to="{
              name: 'persons.show',
              params: { id: item.person_id },
            }"
            link
          >
            <v-list-item-title>
              <v-icon class="mr-1" small>mdi-eye</v-icon>
              {{ $t('ITEM.VIEW') }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Inhabitant',

  filters: {
    date_format(value) {
      if (!value) return 'no value';

      return moment(value).format('DD.MM.YYYY');
    },
  },

  props: {
    inhabitants: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    compareDate: null,
    status: {
      occupy: 'Occupy',
      checkout: 'Check-out',
    },
    selectedInhabitants: [],
    filter: {},
    apartment: null,
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t('PROFILE.FIRST_NAME'),
          value: 'person.first_name',
          filter: (value) => this.filter_column(value, 'first_name'),
        },
        {
          text: this.$t('PROFILE.LAST_NAME'),
          value: 'person.last_name',
          filter: (value) => this.filter_column(value, 'last_name'),
        },
        {
          text: this.$t('INHABITANTS.DATE_FROM'),
          value: 'date_from',
          filter: (value) => this.filter_column(value, 'date_from'),
        },
        {
          text: this.$t('INHABITANTS.DATE_TO'),
          value: 'date_to',
          filter: (value) => this.filter_column(value, 'date_to'),
        },
        {
          text: this.$t('ITEM.STATUS'),
          value: 'status',
        },
        {
          text: this.$t('DATA_TABLE.ACTIONS'),
          value: 'action',
          align: 'center',
        },
      ];
    },
  },

  watch: {
    inhabitants: function (val) {
      if (val.length === 0) {
        this.selectedInhabitants = [];
      }
    },
  },

  created() {
    this.compareDate = moment();
  },

  methods: {
    calcStatus(date_from, date_to) {
      if (moment(date_to).isValid() && moment(date_from).isValid()) {
        return 'checkout';
      } else if (!moment(date_from).isValid()) {
        return 'not valid date from';
      } else if (moment(date_from).isBefore()) {
        return 'occupy';
      } else if (moment(date_from).isAfter()) {
        return 'will occupy';
      } else if (!moment(date_to).isValid()) {
        return 'not valid date to';
      }
    },

    filter_column(value, param) {
      if (!this.filter[param]) return true;
      if (!value) return false;

      let lcValue = (value + '').toLowerCase(); // (value + '') means value.toString()
      let lcName = this.filter[param].toLowerCase();

      return lcValue.indexOf(lcName) > -1;
    },
  },
};
</script>
