var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"my-5"},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('BUILDINGS.INHABITANTS')))]),_c('v-spacer')],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"loading":!!!_vm.inhabitants,"items":_vm.inhabitants,"sort-by":'id',"sort-desc":true,"items-per-page":10,"show-select":""},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":"1"}}),_c('td',[_c('v-text-field',{attrs:{"label":_vm.$t('DATA_TABLE.FILTER')},model:{value:(_vm.filter.first_name),callback:function ($$v) {_vm.$set(_vm.filter, "first_name", $$v)},expression:"filter.first_name"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":_vm.$t('DATA_TABLE.FILTER')},model:{value:(_vm.filter.last_name),callback:function ($$v) {_vm.$set(_vm.filter, "last_name", $$v)},expression:"filter.last_name"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":_vm.$t('DATA_TABLE.FILTER')},model:{value:(_vm.filter.date_from),callback:function ($$v) {_vm.$set(_vm.filter, "date_from", $$v)},expression:"filter.date_from"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":_vm.$t('DATA_TABLE.FILTER')},model:{value:(_vm.filter.date_to),callback:function ($$v) {_vm.$set(_vm.filter, "date_to", $$v)},expression:"filter.date_to"}})],1),_c('td',[_c('v-text-field',{attrs:{"label":_vm.$t('DATA_TABLE.FILTER')}})],1),_c('td')])]},proxy:true},{key:"item.date_from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date_format")(item.date_from))+" ")]}},{key:"item.date_to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date_format")(item.date_to))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calcStatus(item.date_from, item.date_to))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-list-item',{attrs:{"target":"_blank","to":{
            name: 'persons.show',
            params: { id: item.person_id },
          },"link":""}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" "+_vm._s(_vm.$t('ITEM.VIEW'))+" ")],1)],1)],1)]}}]),model:{value:(_vm.selectedInhabitants),callback:function ($$v) {_vm.selectedInhabitants=$$v},expression:"selectedInhabitants"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }