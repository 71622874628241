<template>
  <div>
    <v-toolbar v-if="buildingComplex">
      <v-toolbar-title
        >{{ $t('BUILDING_COMPLEXES.ITEM') }}
        {{ buildingComplex.name }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark color="indigo" @click="$router.go(-1)">
        {{ $t('BUTTON.BACK') }}
      </v-btn>
    </v-toolbar>

    <v-sheet v-if="buildingComplex.name" class="mt-5 pa-5" tile>
      <div v-if="error">
        <v-alert
          v-for="(e, index) in error.data.errors"
          :key="index"
          color="error"
          dark
          dense
        >
          {{ e[0] }}
        </v-alert>
      </div>

      <v-container grid-list-xl>
        <v-layout wrap>
          <v-flex xs12 md8>
            <v-layout wrap>
              <v-flex xs12 md4>
                <v-text-field
                  v-model="buildingComplex.name"
                  :label="$t('ITEM.NAME')"
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  v-model="buildingComplex.type"
                  :label="$t('ITEM.TYPE')"
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  v-model="buildingComplex.status"
                  :label="$t('PROFILE.STATUS')"
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="buildingComplex.management_company.name"
                      :label="$t('BUILDING_COMPLEXES.MANAGEMENT_COMPANY')"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span v-html="infoAboutManagementCompany"></span>
                </v-tooltip>
              </v-flex>

              <v-flex xs12 md4>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="buildingComplex.business_customer.name"
                      :label="$t('BUILDING_COMPLEXES.BUSINESS_CUSTOMER')"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <span v-html="infoAboutBisinessCustomer"></span>
                </v-tooltip>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  v-model="appName"
                  :label="$t('BUILDING_COMPLEXES.APP')"
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  v-model="buildingComplex.zip"
                  :label="$t('PROFILE.ZIP')"
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  v-model="buildingComplex.city"
                  :label="$t('PROFILE.CITY')"
                  readonly
                ></v-text-field>
              </v-flex>

              <v-flex xs12 md4>
                <v-text-field
                  :value="fullNameOfConcierge"
                  :label="$t('BUILDING_COMPLEXES.CONCIERGE')"
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 md4>
            <language-tabs
              ref="languageTabs"
              v-model="buildingComplex.translations"
              :errors="errors.get('translations')"
              #default="{ translation, index, language, on, ...other }"
              eager
            >
              <concierge-info-form
                :value="translation"
                :language-id="language.id"
                v-bind="other"
                :readonly="readonly"
                v-on="on"
                @update-prop="clearErrorByName($event, `translations.${index}`)"
              ></concierge-info-form>
            </language-tabs>
          </v-flex>
          <v-flex xs12 md12>
            <v-btn
              v-if="showInfoscreenButton"
              color="primary"
              outlined
              @click="openInfoscreen"
            >
              {{ $t('INFOSCREEN.ITEM') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-sheet>

    <buildings-of-building-complex
      :refresh-buildings="refreshBuildings"
      :buildings="buildingComplex.buildings"
      :id-building-complex="id"
      :selected-building="selectedBuilding"
      @addSelectedBuildings="addSelectedBuildings"
    ></buildings-of-building-complex>

    <apartment
      :apartments="shownApartments"
      :selected-buildings="selectedBuildings"
      @refreshBuildings="refreshBuildingss"
      @addSelectedApartments="addSelectedApartments"
    ></apartment>

    <inhabitant
      :inhabitants="shownInhabitants"
      @refreshBuildings="refreshBuildingss"
    ></inhabitant>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import BuildingsOfBuildingComplex from '../components/buildings/BuildingsOfBuildingComplex';
import Apartment from '../components/apartments/Apartment';
import Inhabitant from '../components/inhabitants/Inhabitant';
import ConciergeInfoForm from '../components/ConciergeInfoForm';
import LanguageTabs from '@/components/LanguageTabs';
import errors from '@/mixins/errors';
import infoscreen from '../components/common/infoscreen';
import VueCookies from 'vue-cookies';
import { mapGetters } from 'vuex';

export default {
  name: 'BuildingComplexesShowPage',

  directives: {
    mask,
  },

  components: {
    ConciergeInfoForm,
    LanguageTabs,
    BuildingsOfBuildingComplex,
    Apartment,
    Inhabitant,
  },

  mixins: [errors],

  computed: {
    showInfoscreenButton() {
      return Object.values(infoscreen).find((el) => el === this.id);
    },
    ...mapGetters('auth', ['user']),
  },

  props: {
    id: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    shownApartments: [],
    shownInhabitants: [],
    selectedBuildings: [],
    selectedApartments: [],
    buildingComplex: {
      status: 'active',
      translations: [],
    },
    error: null,
    overlay: true,
    selectedBuilding: null,
    refreshBuildings: false,
    fullNameOfConcierge: '',
    appName: null,
    readonly: true,
    tilesGroup: null,
  }),

  watch: {
    selectedBuildings: function (selectedBuildings) {
      this.shownApartments.length = 0;
      if (selectedBuildings.length !== 0)
        selectedBuildings.forEach((el) => {
          el.apartments.forEach((apartment) => {
            apartment.inhabitants_count = apartment.inhabitants.length;
            apartment.status =
              apartment.inhabitants_count > 0 ? 'OCCUPIED' : 'FREE';
          });

          this.shownApartments.push(...el.apartments);
        });
      else if (selectedBuildings.length === 0) {
        this.shownApartments = [];
        this.shownInhabitants = [];
      }
    },
    selectedApartments: function (selectedApartments) {
      this.shownInhabitants.length = 0;
      if (selectedApartments.length !== 0)
        selectedApartments.forEach((el) =>
          this.shownInhabitants.push(...el.inhabitants)
        );
      else if (selectedApartments.length === 0) this.shownInhabitants = [];
    },
  },

  created() {
    this.selectedBuilding = this.$route.params.selectedBuilding;
    this.$store
      .dispatch('building_complex/retrieveBuildingComplex', this.id)
      .then(({ data }) => {
        this.overlay = false;
        this.buildingComplex = data;
        this.fullNameOfConcierge =
          data.concierge.person.first_name +
          ' ' +
          data.concierge.person.last_name;

        this.infoAboutBisinessCustomer = this.showTooltip(
          this.buildingComplex.business_customer.name,
          this.buildingComplex.business_customer.city,
          this.buildingComplex.business_customer.zip,
          this.buildingComplex.business_customer.street,
          this.buildingComplex.business_customer.phone
        );
        this.infoAboutManagementCompany = this.showTooltip(
          this.buildingComplex.management_company.name,
          this.buildingComplex.management_company.city,
          this.buildingComplex.management_company.zip,
          this.buildingComplex.management_company.street,
          this.buildingComplex.management_company.phone
        );

        if (this.buildingComplex.tiles_group_id) {
          this.appName = this.buildingComplex.tiles_group.app.name;
        }
      });
  },

  methods: {
    refreshBuildingss() {
      this.refreshBuildings = !this.refreshBuildings;
    },
    addSelectedBuildings(selectedBuildings) {
      this.selectedBuildings = selectedBuildings;
    },
    addSelectedApartments(selectedApartments) {
      this.selectedApartments = selectedApartments;
    },
    showTooltip(name, city, zip, street, phone) {
      let description = '';
      description += name;
      description += '<br/>';
      description += street;
      description += '<br/>';
      description += zip + ' ' + city;
      description += '<br/>';
      description += phone;
      return description;
    },
    openInfoscreen() {
      const url = this.$config.get('app.urlInfoscreen') + '/dashboard';

      VueCookies.set(
        'infoscreen_access',
        { email: this.user.email },
        1,
        null,
        '.bonacasa.ch',
        true
      );
      window.open(url, '_blank');
    },
  },
};
</script>
