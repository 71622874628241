<template>
  <div>
    <v-toolbar class="my-5">
      <v-toolbar-title>{{ $t('BUILDINGS.ITEMS') }}</v-toolbar-title>
    </v-toolbar>

    <v-data-table
      v-model="selectedBuildings"
      :headers="headers"
      :loading="!!!buildings"
      :items="buildings"
      :sort-by="'id'"
      :sort-desc="true"
      :items-per-page="10"
      show-select
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.elevator="{ item }">
        <v-checkbox v-model="item.elevator" disabled></v-checkbox>
      </template>

      <template v-slot:item.keysafe="{ item }">
        <v-checkbox
          v-if="item.id == item.keysafe_house_building_id"
          v-model="flag"
          disabled
        ></v-checkbox>
        <v-checkbox v-else disabled></v-checkbox>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'BuildingsOfBuildingComplex',

  props: {
    idBuildingComplex: {
      type: Number,
      required: true,
    },
    selectedBuilding: {
      type: Object,
      default: () => {},
    },
    refreshBuildings: {
      type: Boolean,
    },
    buildings: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    keysafeHouses: [],
    keysafeHousesLinked: null,

    flag: true,
    selectedBuildings: [],

    building: null,
  }),

  computed: {
    headers() {
      return [
        {
          text: 'ID',
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('PROFILE.STREET'),
          value: 'street',
        },
        {
          text: this.$t('PROFILE.NAME'),
          value: 'name',
        },
        {
          text: this.$t('ITEM.TYPE'),
          value: 'type',
        },
        {
          text: this.$t('BUILDINGS.ELEVATOR'),
          value: 'elevator',
        },
        // {
        //     text: 'Manual',
        //     value: 1,
        // },
        {
          text: this.$t('BUILDINGS.KEY_SAFE'),
          value: 'keysafe',
        },
        {
          text: this.$t('BUILDINGS.KEY_SAFE_HOUSE'),
          value: 'keysafe_house.street',
        },
        {
          text: this.$t('APARTMENTS.ITEMS'),
          value: 'apartments_count',
        },
        {
          text: this.$t('BUILDINGS.INHABITANTS'),
          value: 'inhabitants_count',
        },
      ];
    },
  },

  watch: {
    buildings: 'refreshData',
    refreshBuildings: 'refreshData',
    selectedBuildings: function (val) {
      this.$emit('addSelectedBuildings', val);
    },
  },

  created() {
    this.refreshData();

    // for selected building from buildings_menu
    if (this.selectedBuilding)
      this.selectedBuildings.push(this.selectedBuilding);
  },

  methods: {
    refreshData() {
      let keysafeHouses = [];
      this.buildings.forEach((building) => {
        if (building.id === building.keysafe_house_building_id)
          keysafeHouses.push(building);
      });
      this.keysafeHouses = keysafeHouses;

      let keysafeHousesLinked = new Set();
      this.buildings.forEach((building) => {
        if (
          building.id !== building.keysafe_house_building_id &&
          building.keysafe_house_building_id != null
        )
          keysafeHousesLinked.add(building.keysafe_house_building_id);
      });
      this.keysafeHousesLinked = keysafeHousesLinked;
      let selecteedids = this.selectedBuildings.map((m) => m.id);
      let filteredData = this.buildings.filter((e) =>
        e ? selecteedids.includes(e.id) : null
      );
      this.selectedBuildings = filteredData;
    },
  },
};
</script>
